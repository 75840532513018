/* rem and em do NOT depend on html font size in media queries !
Instead, 1rem = 1em = 16px */

/****************************/
/* BELOW 1104px */
/****************************/

@media (max-width: 69em) {
  /* GENERAL */
  html {
    /* 9px / 16px */
    font-size: 56.25%;
  }

  /* HERO SECTION */
  .hero {
    margin: 0 2.4rem;
  }

  .hero-image-box {
    width: 70%;
  }
}

/****************************/
/* BELOW 960px */
/****************************/

@media (max-width: 60em) {
  /* SECTION TWO AND FOUR*/
  .section-two-four .grid--2-cols {
    grid-template-columns: 1fr;
    gap: 4.8rem;
  }

  .two-four-text-box {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  .two-four-image-box {
    grid-row: 1;
    width: 50%;
    margin: 0 auto;
  }

  /* SECTION THREE */
  .section-three .grid--3-cols {
    max-width: 80rem;
    grid-template-columns: 1fr 1fr;
    column-gap: 4.8rem;
    row-gap: 2.4rem;
  }

  .three-text-box--last {
    grid-column: 1 / 3;
    width: 40rem;
    margin: 0 auto;
  }

  /* SECTION FIVE */
  .section-five .grid--3-cols {
    gap: 3.2rem;
  }

  /* SECTION CTA */

  .cta .five-box {
    grid-column: 1 / -1;
    max-width: 50rem;
    margin: 0 auto;
  }

  .what-we-do--1 {
    text-align: center;
  }

  .what-we-do .five-header {
    text-align: center;
  }

  .what-we-do--2 {
    margin-bottom: 4.8rem;
  }

  .cta .cta-embed {
    grid-column: 1 / -1;
    width: 70rem;
    margin: 0 auto;
  }
}

/****************************/
/* BELOW 816px */
/****************************/

@media (max-width: 51em) {
  /* HERO SECTION */
  .hero {
    display: grid;
    text-align: center;
    gap: 4.8rem;
  }

  .hero-text-box {
    margin: 0 auto;
  }

  .hero-image-box {
    grid-row: 1;
    margin: 0 auto;
    width: 50%;
  }

  /* SECTION TWO AND FOUR */
  .two-four-text-box {
    width: 70%;
  }

  /* SECTION FIVE */
  .section-five .grid--3-cols {
    grid-template-columns: 1fr 1fr;
  }

  .five-img-box {
    grid-column: 1 / 3;
    width: 30rem;
    margin: 0 auto;
  }

  /* CTA SECTION */
  .cta .cta-embed {
    width: 60rem;
  }
}

/****************************/
/* BELOW 640px */
/****************************/

@media (max-width: 40em) {
  /* GENERAL */
  html {
    /* 8px / 16px = 0.5 = 50% */
    font-size: 50%;
  }

  /* SECTION HERO */
  .hero {
    padding: 0;
  }

  /* SECTION TWO AND FOUR */
  .two-four-text-box {
    width: 90%;
  }

  /* SECTION THREE */
  .section-three .grid--3-cols {
    grid-template-columns: 1fr;
    row-gap: 2.4rem;
    margin: 0 auto;
    padding: 0 auto;
  }

  .three-text-box {
    max-width: 40rem;
    margin: 0 auto;
  }
  .three-text-box--last {
    grid-column: 1;
  }

  /* SECTION FIVE */
  .section-five .grid--3-cols {
    padding: 0 1.2rem;
    column-gap: 1.2rem;
  }

  /* SECTION CTA */
  .cta .five-box {
    max-width: 45rem;
  }

  .cta .cta-embed {
    width: 80%;
  }
  .section-cta .container {
    padding: 0;
  }
}

/****************************/
/* BELOW 464px */
/****************************/

@media (max-width: 29em) {
  /* GENERAL */
  .heading-primary {
    font-size: 3.2rem;
    margin-bottom: 2.4rem;
  }

  .section-hero {
    padding: 2.4rem 0 4.8rem 0;
  }

  .section-two-four,
  .section-three,
  .section-five,
  .section-cta {
    padding: 4.8rem 0;
  }

  /* HERO SECTION */
  .hero-image-box {
    width: 70%;
  }

  /* SECTION TWO AND FOUR */
  .section-two .grid--2-cols {
    gap: 2.4rem;
  }

  .section-two-four .two-four-image-box {
    width: 70%;
  }

  /* SECTION THREE */
  .three-text-box {
    max-width: 30rem;
  }

  /* SECTION FIVE */
  .section-five .grid--3-cols {
    grid-template-columns: 1fr;
    padding: 0 6.2rem;
    gap: 0;
  }

  .five-img-box {
    grid-column: 1;
    width: 30rem;
    margin: 0 auto;
  }

  /* SECTION CTA */
  .cta .five-box {
    max-width: 40rem;
    padding: 1.2rem;
  }

  .cta .cta-embed {
    width: 100%;
    background-image: none;
    box-shadow: none;
    padding: 0;
  }
}
